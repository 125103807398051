<template>
    <div class="content">
        <h1 id="h1-u7535u68AFu667Au8054u9690u79C1u653Fu7B56" style="text-align: center;"><a name="隱私政策" class="reference-link"></a><span class="header-linkoctic -link"></span>隱私政策</h1>
         <p>更新日期：2022年 9月 15日<br>生效日期：2022年 9月 15日</p>
         <p>為實際保護使用者個人資訊保護，優化使用者體驗，香港中醫在綫（以下簡稱「我們」）依據現行法規及政策，制定"大醫"這款APP的個人資訊保護政策。 我們了解個人資訊對客戶的重要性，我們力求明確說明我們取得、管理及保護使用者個人資訊的政策及措施。
         </p>
         <p>本個人資訊保護政策將幫助您了解以下內容： </p>
         <p><a style="text-decoration: none;">一、我們收集哪些您的個人資訊</a></p>
         <p><a style="text-decoration: none;">二、Cookie和網路Beacon等同類技術的使用 </a></p>
         <p><a style="text-decoration: none;">三、我們如何使用您的個人資訊</a></p>
         <p><a style="text-decoration: none;">四、我們如何委託處理、分享、轉移、公開揭露您的個人資訊 </a></p>
         <p><a style="text-decoration: none;">五、一般儲存期限 </a></p>
         <p><a style="text-decoration: none;">六、我們如何確保您的資訊安全 </a></p>
         <p><a style="text-decoration: none;">七、您的權利 </a></p>
         <p><a style="text-decoration: none;">八、本個人資訊保護政策不適用的範圍</a></p>
         <p><a style="text-decoration: none;">九、本個人資訊保護政策如何更新</a></p>
         <p><a style="text-decoration: none;">十、聯絡我們 </a></p>
         <p>
         本個人資訊保護政策適用於引用或連結本個人資訊保護政策的網站或應用程式（統稱為我們的「服務」），即大醫APP、網站。 雖然本個人資訊保護政策適用於所有這些服務，但我們也針對特定服務制定了某些明確的個人資訊保護補充條款，其中包含有關我們的資訊處理做法的其他資訊。 使用相關的服務時，您應遵守這些補充條款。 <br>
         我們深知個人資訊對您的重要性，並會盡全力保護您的個人資訊安全可靠。 我們致力於維持您對我們的信任，恪守以下原則保護您的個人資訊：權責一致原則、目的明確原則、選擇同意原則、最少夠用原則、確保安全原則、主體參與原則、公開透明原則等。 同時，我們承諾，我們將以業界成熟的安全標準，採取相應的安全保護措施來保護您的個人資訊。
         </p>
         <p><strong>請務必仔細閱讀本個人資訊保護政策，特別是免除/限制責任的條款（以粗體標識）,因為只要您使用我們的服務，就表示您同意個人資訊保護政策及補充條文 中所描述的做法。 如果您不同意本個人資訊保護保單中所描述的做法，您將無法使用我們的服務。
         </strong></p>
         <div id="partOne"></div>
         <h2><a name="一、我們收集哪些您的個人資料" class="reference-link"></a><span class="header-link octicon octicon-link"></span>一、 我們收集哪些您的個人資訊</h2>
         <p>
         為了提供您我們的服務，我們會要求您提供向您提供服務所需的個人資訊。 如果您不提供個人資訊，我們可能無法向您提供我們的產品或服務。 「個人資訊」指以電子或其他方式記錄的能夠單獨或與其他資訊結合識別特定自然人身分或反映特定自然人活動情況的各種資訊。 <br>1.您提供的個人資訊：某些服務會要求您直接向我們提供訊息，如用登入、修改手機號碼、修改密碼。
         </p>
         <p>(1)登入：當您登入大醫APP時，需要您輸入網域名稱、使用者名稱和密碼。 </p>
         <p>(2)修改密碼：當您修改密碼時，需要您輸入舊密碼、新密碼。 </p>
         <p>2.來自設備的個人資訊：我們也可能透過您設備中的軟體以及其他方式來收集您使用我們的服務的相關信息，如設備信息、日誌信息、位置信息。 </p>
         <p>
         (1)設備資訊：您下載或使用大醫APP及服務時，我們可能會讀取與您行動裝置相關的信息，包括硬體型號、IMEI號碼和其他唯一設備識別碼、作業系統版本以及用於訪問 服務的設備的設定。
         </p>
         <p>
         (2)日誌資訊：當您使用我們服務，或造訪大醫APP相關的我們網站時，我們自動接收並記錄的您的瀏覽器和電腦上的信息，包括瀏覽器的類型、使用的語言、訪問 日期及時間、軟硬體特徵資訊及您需求的網頁記錄資料。
         </p>
         <p>
         (3)位置資訊（僅適用於特定服務/功能）：當您使用大醫APP在「新增醫師」和「上下班打卡」這兩項功能頁面時，我們會請求您授權訪問地理位置這項 敏感權限。 我們可能會讀取與您位置相關的各類信息，如設備的GPS信號或附近的WiFi接入點和手機信號發射塔的相關信息、國家代碼、城市代碼、小區標識、經緯度信息和語言設置。 您如果拒絕授權提供，將無法使用此功能，但不影響您正常使用我們提供的其他功能。
         </p>
         <p><strong>3.您可以選擇不提供某些類型的信息或恢復出廠設定來單獨取消每個聯網設備與互聯網之間的連接的方式，不向我們伺服器傳輸我們日誌資訊。 但這樣可能會導致您將無法使用APP的某些功能。
         </strong></p>
         <p>4.裝置權限呼叫：我們大醫APP可能會呼叫您的裝置權限，以下是呼叫權限對應的業務功能、我們呼叫權限的目的、以及在呼叫權限前向您詢問的情況。 </p>
         <table border="1" cellspacing="0">
         <thead>
         <tr>
         <th style="width: 120px;text-align: center;">申請權限</th>
         <th>申請權限的目的</th>
         <th>是否詢問</th>
         <th>使用者是否可關閉</th>
         </tr>
         </thead>
         <tbody>
         <tr>
            <td style="width: 120px;text-align: center;">日曆</td>
            <td>添加用藥提醒</td>
            <td>首次使用對應功能時彈框詢問</td>
            <td style="text-align: center;">是</td>
         </tr>
         <tr>
         <td style="width: 120px;text-align: center;">相機</td>
         <td>修改頭像、諮詢醫師、實名認證、新增身體檢查記錄、新增用藥提醒時拍攝照片</td>
         <td>首次使用對應功能時彈框詢問</td>
         <td style="text-align: center;">是</td>
         </tr>
         <tr>
         <td style="width: 120px;text-align: center;">麥克風</td>
         <td>諮詢醫師時的自訂拍照功能</td>
         <td>首次使用對應功能時彈框詢問</td>
         <td style="text-align: center;">是</td>
         </tr>
         <tr>
         <td style="width: 120px;text-align: center;">位置/精確位置</td>
         <td>手動新增醫師時需要取得目前位置信息，上下班打卡頁面需要取得目前的準確經緯度資訊位置資訊</td>
         <td>首次使用對應功能時彈框詢問</td>
         <td style="text-align: center;">是</td>
         </tr>
         <tr>
         <td style="width: 120px;text-align: center;">相片及媒體</td>
         <td>修改頭像、諮詢醫師、實名認證、新增體檢記錄從相簿選擇圖片</td>
         <td>Android客戶端會在首次儲存照片時詢問</td>
         <td style="text-align: center;">是</td>
         </tr>
         <tr>
         <td style="width: 120px;text-align: center;">存儲</td>
         <td>用於存儲應用使用過程中的緩存數據、文件下載</td>
         <td>首次使用對應功能時彈框詢問</td>
         <td style="text-align: center;">是</td>
         </tr>
         <tr>
            <td style="width: 120px;text-align: center;">通知</td>
            <td>需要透過通知發送預約結果、諮詢回覆內容、APP其他功能提醒</td>
            <td>iOS客戶端首次打開APP時彈框詢問</td>
            <td style="text-align: center;">是</td>
         </tr>
         <tr>
            <td style="width: 120px;text-align: center;">網絡訪問</td>
            <td>需要網路請求APP相關功能資料</td>
            <td>iOS客戶端會在首次開啟APP時詢問</td>
            <td style="text-align: center;">是</td>
         </tr>
         </tbody>
         </table>
         <h2 id="partTwo"><a name="二、Cookie與網路Beacon等同類技術的使用" class="reference-link"></a>
         <span class="header-link octicon octicon-link"></span>二、Cookie和網路Beacon等同類技術的使用</h2>
         <p>1.Cookie技術：您造訪我們相關網站或使用我們提供的服務時，我們可能會透過小型資料檔案識別您的身份，這麼做是為了幫您省去重複輸入註冊資訊的步驟，或協助 判斷您的帳戶安全。 這些資料檔案可能是Cookie、Flash
         Cookie或您的瀏覽器或關聯應用程式提供的其他本機儲存體（統稱「Cookie」）。 </p>
         <p>
         Cookie是電腦、電視、手機或其他裝置上儲存資訊的小檔案。 在您的裝置上放置Cookie的實體可以透過Cookie在不同網站、服務、裝置和/或瀏覽工作階段中識別您。 Cookie具有許多用途。 例如：Cookie可以記住您的登入憑證，這樣您就不必在每次登入服務時輸入這些憑證。 Cookie可協助我們和第三方了解我們的哪一部分服務最受歡迎，因為Cookie有助於我們查看訪客造訪的是哪些頁面和功能，以及在這些頁面上花費多長時間。 在了解此類資訊後，我們就能更有效地改善服務並為您提供更好的體驗。 Cookie透過收集您使用我們的服務以及其他網站和應用程式的資訊。 如果您使用網頁瀏覽器存取服務，可以將瀏覽器設定為接受所有Cookie、拒絕所有Cookie或傳送Cookie時通知您。 每種瀏覽器都是不同的，因此請查看瀏覽器的「說明」功能表以了解如何變更您的Cookie首選項。 您的裝置的作業系統可能包含其他Cookie控制項。
         </p>


         <p>
            <strong>請您瞭解，我們的某些服務只能透過使用「Cookie」才能實現。 如果您的瀏覽器或瀏覽器附加服務允許，您可以根據自己的偏好修改對Cookie的接受程度或拒絕我們的Cookie，您可以清除電腦上儲存的所有Cookie，大部分網路瀏覽器都設有阻止Cookie 的功能，修改或刪除Cookie的方法，請參閱您的瀏覽器的使用協助。 但此舉動在某些情況下可能會影響您安全地造訪我們相關網站和使用我們提供的服務。 </strong>
            </p>
            <p>2.網站信標與像素標籤 </p>
            <p>
            除Cookie外，我們也會在網站上使用網站信標和像素標籤其他同類技術。 如網頁上常會包含一些電子圖像（稱為「單像素」GIF檔案或「網頁beacon」），使用網路beacon可以幫助網站計算瀏覽網頁的使用者或造訪某些cookie，我們會透過網路beacon收集您瀏覽網頁 活動的訊息，例如您造訪的頁面位址、您先前造訪的引用頁面的地址、您停留在頁面的時間、您的瀏覽環境以及顯示設定。
            </p>
            <p>3.行動分析軟體 </p>
            <p>
            在大醫APP中，我們使用行動分析軟體，我們行為統計SDK，以便更好地了解我們的APP在您的手機中的功能。 此款軟體可能記錄以下訊息，如您使用該應用程式的頻率、該應用程式內發生的事件、累計使用情況、效能數據及應用程式崩潰發生的位置。 我們不會將儲存於分析軟體內的資訊關聯到您在APP中提交的任何個人資訊。
            </p>
            <p> 4.高德SDK </p>
            <p> 我們會根據高德SDK提供的GPS資料獲得您的位置資訊和地圖展示，用於與位置相關的大醫APP服務，如：新增醫師、上下班打卡。 </p>
            <h2 id="partThree"><a name="三、我們如何使用您的個人資料" class="reference-link"></a><span class="header-link octicon octicon-link"></span>三、我們如何使用您的個人資料</h2>
            <p><strong>收集個人資訊的目的在於向您提供產品和/或服務，並且保證我們遵守適用法律。 我們可能將所收集的個人資訊用於以下用途和目的：</strong></p>
            <p>1.收集使用者回饋。 您選擇提供的回饋對幫助我們改善我們的服務極為珍貴。 為了追蹤您選擇提供的回饋，我們可能會使用您所提供的個人資訊與您聯繫，並保留記錄。 </p>
            <p>
            2.評估和分析市場、客戶、產品及服務（包括詢問您對我們的產品和服務的意見，以及執行客戶調查），改善使用者體驗。 我們可能會將某項服務的個人資訊與其他服務的資訊結合起來，用於改善服務並開發新產品和服務。 我們可能使用某些選擇性加入功能（例如使用者體驗計劃）的數據，以分析使用者如何使用裝置和我們服務，改善使用者體驗。
            </p>
            <p>3.發送通知。 我們可能會不時使用您的個人資訊來發送重要通知，例如與業務相關的資訊。 </p>
            <p><strong>5.安全管理。 保障提供給您的服務的安全穩定運行，我們可能會不時使用您的設備資訊和日誌資訊。 </strong> </p>
            <p>4.提供其他我們服務。 </p>
            <p>
            <strong>請注意，以上的使用方式涵蓋了我們使用個人資訊的主要方式。 我們可能不時透過更新網站頁面、APP版本的方式，為使用者提供更多樣化的服務。 </strong><br>當我們要將資訊用於本政策未載明的其他用途時，或要將基於特定目的收集而來的資訊用於其他目的時，會以確認協議、具體場景下的 確認行為、更新本個人資訊保護協議的形式再次徵求您的同意。
            </p>
            <h2 id="partFour"><a name="四、我們如何委託處理、分享、轉讓、公開揭露您的個人資訊" class="reference-link"></a><span class="header- link octicon octicon-link"></span>四、我們如何委託處理、分享、轉移、公開揭露您的個人資訊</h2>
            <p>1.委託處理 </p>
            <p>本業務功能中某些特定的模組或功能由外部供應商提供。 例如我們會聘請服務提供者來協助我們提供客戶支援。
            對我們委託處理個人資訊的公司、組織和個人，我們會與其簽署嚴格的保密協定，要求他們按照我們的要求、本個人資訊保護政策以及其他任何相關的保密和安全措施來處理個人資訊。 </p>
            <p>2.共享 </p>
            <p><strong>未經您的同意，我們將不會向我們以外的第三方分享您的資訊</strong>。 但是，我們可能和下列第三方共享您的資訊： </p>
            <p>
            <strong>(1)我們關聯公司。 為便於我們基於我們平台統一帳號系統向您提供服務，推薦您可能感興趣的資訊或保護我們或其他用戶或公眾的人身財產安全免遭侵害，您的個人資訊可能會在我們關聯公司之間共享 。 </strong>我們只會分享必要的個人訊息，如果我們分享您的個人敏感資訊或關聯公司改變個人資訊的使用及處理目的，將再次徵求您的授權同意。
            </p>
            <p>
            (2)<strong>授權合作夥伴。 </strong>我們可能與可信任的授權合作夥伴分享您的資訊。 我們可能會向授權合作夥伴分享您的<strong>帳戶資訊、設備資訊</strong>，以保障為您提供的服務順利完成。 但我們只會出於合法、正當、必要、特定、明確的目的共享您的個人訊息，並且只會分享提供服務所需的個人資訊。 我們的合作夥伴無權將共享的個人資訊用於任何其他用途。 目前，我們的授權合作夥伴包括以下類型：
            </p>
            <p>a.分析服務類的授權合作夥伴。 </p>
            <p>
            b.供應商、服務提供者和其他合作夥伴。 我們的供應商和服務提供者包括提供技術服務、電信服務、客戶服務、風控服務、審計服務、法律服務的機構、金融機構。 <br>對我們與之分享個人資訊的公司、組織和個人，我們會與其簽署嚴格的保密協定，並會以適當的方式要求他們按照我們的說明、本個人資訊保護政策以及其他任何相關的保密 和安全措施來處理個人資訊。 在個人敏感資料使用上，我們要求第三方採用加密技術，以便更好地保護用戶資料。 一經發現其違反協議約定將會採取有效措施乃至終止合作。
            </p>
            <p>遵守法律法規強制性要求、法院命令或政府主管機關要求、維護社會公共利益，或保護我們的客戶、我們或我們員工的人身和財產安全或合法權益所合理必需的用途。 </p>
            <p>(3)單獨獲得您同意的其他方。 除本個人資訊保護政策中所述的共享情況之外，我們也可能在您同意分享或提出共享請求時與第三方分享您的資訊。 </p>
            <p>3.轉讓 </p>
            <p>我們不會將您的個人資訊轉移給任何公司、組織和個人，但以下情況除外： </p>
            <p>(1)獲得您的明確同意後； </p>
            <p>(2)在涉及合併、收購或破產清算時，如涉及個人資訊轉讓，我們會在要求新的持有您個人資訊的公司、組織繼續受此個人資訊保護政策的約束，否則我們 將要求該公司、組織重新向您徵求授權同意。 </p>
            <p>4.公開揭露 我們只會在下列情況下，公開揭露您的個人資訊： </p>
            <p>(1)獲得您明確同意後； </p>
            <p>(2)基於法律的揭露：基於法律法規強制性要求、法院命令或政府主管要求的情況下，我們會公開揭露您的個人資訊。 </p>
            <h2 id="partFive"><a name="五、一般儲存期限" class="reference-link"></a><span class="header-link octicon octicon-link"></span>五 、一般儲存期限</h2>
            <p><strong>除非法律另有明文規定或經您允許，我們只會在本個人資訊保護政策所述目的所需的期限內保留您的個人資訊。 為了保持服務的持續性和使用者體驗的一致性，個人資訊通常不會在單一服務完成後立即刪除。 </strong> </p>
            <h2 id="partSix"><a name="六、我們如何確保您的資訊安全" class="reference-link"></a><span class="header-link octicon octicon-link"></span>六、我們如何確保您的資訊安全</h2>
            <p>1.資料安全技術措施 </p>
            <p>
            我們已使用符合業界標準的安全防護措施保護您提供的個人信息，防止資料遭到未經授權存取、公開揭露、使用、修改、損壞或遺失。 我們會採取一切合理可行的措施，保護您的個人資訊。 例如，在您的瀏覽器與「服務」之間交換資料（如信用卡資訊）時受SSL加密保護；我們同時對部分網站提供https安全瀏覽方式；我們會使用加密技術確保資料的保密性；對使用者 密碼我們採用md5加密機制散列化保存；我們會使用受信賴的保護機制防止資料遭到惡意攻擊；我們會部署存取控制機制，確保只有授權人員才可存取個人資訊；為保障您的資訊安全， 我們努力採取各種合理的實體、電子和管理方面的安全措施來保護您的訊息，使您的資訊不會被洩漏、毀損或遺失，包括但不限於SSL、資訊加密儲存、資料中心的存取控制 。
            </p>
            <p>
            我們透過建立資料安全管理規範、資料安全開發規範來管理和規範個人資訊的儲存和使用。 我們會舉辦安全和個人資訊保護培訓課程，加強員工對保護個人資訊重要性的認知。 我們對可能接觸到您的資訊的員工或外包人員、供應商合作夥伴也採取了嚴格管理，包括但不限於根據職位的不同採取不同的權限控制，與他們簽署保密協議和數據處理協議，監控他們 的操作情況措施。 我們會依照現有技術提供相應的安全措施來保護您的訊息，提供合理的安全保障，我們將盡力做到使您的資訊不被洩漏、毀損或遺失。
            </p>
            <p>
            <strong>2.網路環境並非百分之百安全，我們將盡力確保或擔保您發送給我們的任何資訊的安全性。 </strong>如果我們的實體、技術、或管理防護設施遭到破壞，導致資訊被非授權存取、公開揭露、篡改、或毀壞，導致您的合法權益受損，我們將承擔相應的法律責任。
            </p>

            <p>
                3.在發生個人資訊安全事件後，我們將依照法律法規的要求，及時向您告知：安全事件的基本情況和可能的影響、我們已採取或將要採取的處置措施、您可自主防範和降低風險 的建議、對您的補救措施等。 我們將及時將事件相關情況告知您。 <br><strong><br>4.您的帳號皆有安全保護功能，請妥善保管您的帳號及密碼資訊。 同時，為了保障您的資訊安全，請在向第三方永久性售出或轉讓您的設備使用權之前，確保退出您的帳戶並刪除配對網站或APP，以確保您的設備不再（重新）匹配 到您的帳戶。 如果您售出的是設備，則應重設設備並恢復原廠設定。 這樣，您的裝置與帳戶之間將不再關聯。 如果您購買了二手的設備，請檢查APP中或設備商的帳戶設定以確保沒有未知使用者和您的設備相關聯。 如果還有任何疑問，您可以選擇恢復原廠設定。 我們將透過備份、對使用者密碼進行加密安全措施確保您的資訊不會遺失，不會被濫用和變造。 儘管有前述安全措施，但同時也請您理解在資訊網路上不存在「完美的安全措施」。 </strong>
                </p>
                <h2 id="partSeven"><a name="七、您的權利" class="reference-link"></a><span class="header-link octicon octicon-link"></span>七 、您的權利</h2>
                <p>依照與中國相關的法律、法規、標準，以及其他國家、地區的通行做法，我們保障您對 自己的個人資訊行使以下權利：<br>1.存取您的個人資訊 </p>
                <p>
                您有權存取您的個人資訊。 如果您想行使資料存取權，可以透過以下方式自行存取。 帳戶資訊：如果您希望存取或編輯您的帳戶中的個人資料資訊、更改您的密碼、修改手機號碼或登出您的帳戶，您可以登入您的帳戶後透過造訪設定頁面執行此類操作。 登陸我們大醫APP，進入右下角【我的】頁面，即可存取您的個人資料、帳號名稱、手機號碼。
                </p>
                <p>2.更正您的個人資料</p>
                <p>當您發現我們處理的關於您的個人資訊有錯誤時，您有權自行或要求我們進行更正。 對於帳戶訊息，您可以登入您的帳戶後透過造訪設定頁面執行此類操作。 其他個人訊息，請透過第十二節列明的聯絡方式與我們取得聯繫</p>
                <p>3.刪除您的個人資料 </p>
                <p>在下列情形中，您可以向我們提出刪除個人資訊的請求： </p>
                <p>(1) 如果我們處理個人資訊的行為違反法律法規； </p>
                <p>(2) 如果我們收集、使用您的個人信息，卻未徵得您的同意； </p>
                <p>(3) 如果我們處理個人資訊的行為違反了與您的約定； </p>
                <p>(4) 如果您登出了帳號； </p>
                <p>(5)如果我們不再為您提供產品和服務。 </p>
                <p>當您從我們的服務中刪除信息後，我們可能不會立即在備份系統中刪除相應的信息，但會在備份更新時刪除這些信息。 </p>
                <p>4.改變您授權同意的範圍 </p>
                <p>
                您可以透過提交要求，撤銷授權我們收集、使用和/或揭露我們掌握或控制的您的個人資訊的許可。 您可以透過第十二節列明的聯絡方式聯絡我們進行相關操作。 我們將會在您做出請求後的合理時間內處理您的請求，並且會根據您的請求，在此後不再收集、使用和/或披露您的個人資訊。 <strong>請注意，由於每個業務場景需要一些基本的個人資訊才能得以完成，您撤銷同意可能導致部分我們服務不可用.</strong>
                </p>
                <p>您可以透過行動裝置的設定功能，關閉對應權限（包括位置、照片、麥克風、相機、通知等），撤回對取得您個人資訊的授權。 </p>
                <p>當您撤回同意後，我們將不再處理相應的個人資訊。 但您撤回同意的決定，不會影響先前基於您的授權而進行的個人資訊處理。 </p>
                <p>5.註銷帳戶 </p>
                <p>您隨時可透過第十二節列明的聯絡方式與我們取得聯繫，並註銷先前註冊的帳戶。 </p>
                <p>
                註銷帳戶會影響您正常使用所有相關應用程式和服務。 <strong>我們在此善意地提醒您，您註銷帳戶的行為會為您後續使用我們服務帶來諸多不便，且註銷我們帳戶後，我們將停止為您提供服務，您的個人資訊將保持不可被 檢索、存取的狀態，或被匿名化處理，法律法規另有規定的除外。 </strong>如您透過第三方帳號快速登入的，您需要聯絡該第三方處理註銷帳號事宜。
                </p>
                <p>6.取得您的資訊副本 </p>
                <p>如果您需要取得您的資訊副本，可與十二節列明的聯絡方式與我們聯絡。 如果我們不需要過多投入，我們會向您提供符合法律法規要求的資訊副本。 </p>
                <p>7.如您無法透過上述方式存取、更正、刪除個人訊息，撤回同意或註銷帳戶，您可以透過第十二節列明的聯絡方式與我們取得聯繫。 我們可能需要驗證您的身份，並在驗證您的身份後15個工作天作出答覆或合理解釋。 </p>
                <h2 id="partTen"><a name="八、本個人資訊保護保單不適用的範圍" class="reference-link"></a><span class="header-link octicon octicon-link" ></span>八、本個人資訊保護政策不適用的範圍</h2>
                <p> <strong>我們的個人資訊保護政策不適用於第三方提供的產品和服務。 我們產品和服務可能包括第三方的產品和服務，以及第三方網站的連結。 當您使用這些產品或服務時，也可能收集您的資訊。 因此，我們強烈建議您花時間閱讀該第三方的個人資訊保護政策，就像閱讀我們的政策一樣。 我們不對第三方如何使用他們向您收集的個人資訊負責，也不能控制其使用。 我們的個人資訊保護政策不適用透過我們的服務連結的其他網站
                </strong></p>
                <p>當您使用這些特定產品時（僅為舉例說明，並非窮盡式列舉），適用於以下第三方條款和個人資訊保護政策： </p>
                <p>1.付款：使用支付寶、微信支付或其他第三方支付服務完成、領取直播平台紅包和支付訂單時，您將同意第三方支付服務供應商的個人資訊保護政策適用於您在其網站上 提供的資訊。 </p>
                <p>2.社群媒體（功能）：我們的網站包含一些社群媒體功能，例如「分享」按鈕。 社群媒體功能是由第三方提供的，您與這些功能的互動均受該第三方產品的個人資訊保護政策的限制。 </p>
                <p>
                3.快速登入：您可以使用第三方帳號登入我們的網站或應用程式。 這些服務將驗證您的身份，並讓您選擇是否與我們分享某些個人資訊（例如您的姓名和電子郵件地址），以預先填寫我們的使用者註冊表單或綁定您已有的我們帳戶。 您使用的這些第三方帳號受到該第三方對應個人資訊保護政策的限制。
                </p>

                <p>4.使用第三方功能：您可能透過我們提供的應用程式端使用第三方服務，例如開啟、存取、瀏覽、擷取或註冊登入影片網站、音樂應用程式、地圖應用程式、天氣查詢應用程式。 您在使用第三方功能時需要遵守第三方的服務條款和個人資訊保護政策。 </p>
                <h2 id="partEleven"><a name="九、本個人資訊保護保單如何更新" class="reference-link"></a><span class="header-link octicon octicon-link"></span>九、本個人資訊保護政策如何更新</h2>
                <p>
                <strong>我們的個人資訊保護政策可能會變更。 未經您明確同意，我們不會削減您依本個人資訊保護政策所應享有的權利。 我們會在本頁面上發布對本個人資訊保護政策所做的任何變更。 </strong>對於重大變更，我們也會提供更顯著的通知（包括對於某些服務，我們會透過電子郵件發送通知，說明個人資訊保護政策的具體變更內容）。 本政策所指的重大變更包括但不限於：
                </p>
                <p>1. 我們的服務模式（如處理個人資訊的目的、處理的個人資訊類型、個人資訊的使用方式）發生重大變化； </p>
                <p>2. 我們的所有權結構發生重大變化（如業務調整、破產併購引起的所有者變更）； </p>
                <p>3. 個人資訊公開揭露的主要對象發生變化； </p>
                <p>4. 參與個人資訊處理方面的權利及其行使方式有重大變化； </p>
                <p>5. 我們負責處理個人資訊安全的責任部門、聯絡方式及投訴管道發生變化時； </p>
                <p>6.個人資訊安全影響評估報告顯示有高風險時。 </p>
                <h2 id="partTwelve"><a name="十、聯絡我們" class="reference-link"></a><span class="header-link octicon octicon-link"></span>十、 聯絡我們</h2>
                <p>
                如果您對我們的個人資訊處理行為有任何投訴舉報需求，您可以透過<a target="_blank" href='https://www.ectcm.com/home/feedback'>「用戶回饋」</a >、下方的聯絡方式與我們聯繫並充分描述，我們將在驗證您身分的15天內回覆您的請求並盡力解決。
                </p>
                <p>聯絡方式 </p>
                <p>熱綫電話： (852) 3590 4880</p>
                <p>電郵地址： info@ectcm.com</p>
                <p>Whatsapp： 93839432</p>
    </div>
</template>
<script>
export default {
   name: 'UserPrivacy'
}
</script>

<style scoped>
    * {
        font-size: 36px;
    }
    .content {
        background-color: #fff;
        color: #333;
    }
    table,
    h2,
    p {
        margin: 20px 32px;
    }

    h2 {
        font-size: 38px;
    }

    h1 {
        font-weight: bold;
        font-size: 42px;
        line-height: 100px;
    }
    
</style>